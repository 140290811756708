import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { useNavigate } from "react-router-dom";
import { SquareButton } from "../../common/Buttons/Buttons";
import goodTrainingExamples from "../../../assets/images/good_training_ex.webp";
import badTrainingExamples from "../../../assets/images/bad_training_ex.webp";
import badTrainingExamples2 from "../../../assets/images/bad_training_ex_2.webp";
import "./UploadPage.css";
import { v4 as uuidv4 } from "uuid";
import DropUploader from "./DropUploader/DropUploader";
import { LocalStorageKeys } from "../../../utils/constants";
import { createTransformPartnershipJob } from "../../../utils/graphql";

function UploadPage() {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/upload",
      title: "Upload",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [submitting, setSubmitting] = useState(false);

  const navigate = useNavigate();

  const createJobAndNav = async () => {
    if (submitting) return;
    ReactGA.event({
      category: "cta_button",
      action: "click",
      label: "Continue",
    });

    try {
      const jobCreated = await createTransformPartnershipJob();

      if (jobCreated === true) {
        navigate("/baby-profile");
      }
    } catch (error) {
      console.error("Error creating partnership job:", error);
      // Handle error appropriately (e.g., show error message to user)
    }

    setSubmitting(false);
  };

  const jobId = localStorage.getItem(LocalStorageKeys.jobId) || uuidv4();

  const [numPhotosUploaded, setNumPhotosUploaded] = useState(0);

  const handleUploadCountChange = (uploadCount) => {
    setNumPhotosUploaded(uploadCount);
  };

  useEffect(() => {
    localStorage.setItem(LocalStorageKeys.jobId, jobId);
  }, [jobId]);

  const ctaText =
    numPhotosUploaded === 10
      ? "Continue"
      : `Upload ${10 - numPhotosUploaded} More Photo${
          10 - numPhotosUploaded > 1 ? "s" : ""
        }`;

  return (
    <div className="uploadPage">
      <h1>Upload Photos</h1>
      <div className="uploadPageContainer">
        <div className="infoColumn">
          <p>
            Select <strong>10 of your best photos</strong> of your little one.
            Please take your time and choose carefully. Good uploads will result
            in amazing portraits!
          </p>
          <div className="goodExamples">
            <p>
              <strong>REQUIREMENTS</strong>
            </p>
            <p>
              ✅ <strong>Variety:</strong> Different outfits and backgrounds
            </p>
            <p>
              ✅ <strong>Clear:</strong> Face is clearly visible with good
              lighting
            </p>
            <p>
              ✅ <strong>Solo:</strong> Child is the only one in the photo
            </p>
            <p>
              ✅ <strong>Consistent Eyes:</strong> Eyes either all open, or all
              closed, across photos
            </p>
            <p>
              ✅ <strong>Consistent Mouth:</strong> Mouth either all open, or
              all closed, across photos
            </p>
            <img alt="good training example" src={goodTrainingExamples} />
          </div>
          <div className="badExamples">
            <p>
              <strong>RESTRICTIONS</strong>
            </p>
            <p>
              ❌ <strong>No Similar Photos:</strong> No photos with the same
              outfit or background
            </p>
            <img alt="bad training example" src={badTrainingExamples} />
            <p>
              ❌ <strong>No Other People:</strong> No photos with other
              people&apos;s faces and hands in them
            </p>
            <p>
              ❌ <strong>No Clutter:</strong> No sunglasses, hats, food,
              strollers, car seats, or other items that cover your child&apos;s
              face or body
            </p>
            <p>
              ❌ <strong>No Mixed Open/Closed Eyes:</strong> No mix of
              open/closed eyes across photos
            </p>
            <p>
              ❌ <strong>No Mixed Open/Closed Mouth:</strong> No mix of
              open/closed mouth across photos
            </p>
            <p>
              ❌ <strong>No Goofy Poses:</strong> No unusual facial expressions
              or hand gestures
            </p>

            <img alt="bad training example" src={badTrainingExamples2} />
          </div>
        </div>
        <div className="uploadColumn">
          <DropUploader
            onUploadCountChange={handleUploadCountChange}
            jobId={jobId}
          />
          <SquareButton
            className="stickyUploadButton"
            disabled={numPhotosUploaded !== 10}
            onClick={createJobAndNav}
          >
            {ctaText}
          </SquareButton>
          <div className="gradient"></div>
        </div>
      </div>
    </div>
  );
}

export default UploadPage;
