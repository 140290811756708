import { useEffect } from "react";
import ReactGA from "react-ga4";
// import { SquareButton } from "../../common/Buttons/Buttons";
import FeedbackModal from "./FeedbackModal";

function DownloadCompletePage() {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/download-complete",
      title: "Download Complete",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const navigate = useNavigate();

  return (
    <div style={styles.container}>
      <h1 style={styles.header}>🎉 Download Complete</h1>
      {/* <p style={styles.paragraph}>We hope you love your photos!</p>/ */}
      <FeedbackModal></FeedbackModal>
      {/* <SquareButton
        style={styles.cta}
        onClick={() => navigate("/photoshoot-gallery")}
      >
        Back to My Photoshoots →
      </SquareButton> */}
    </div>
  );
}

export default DownloadCompletePage;

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "1.5rem",
    maxWidth: "390px",
    margin: "0 auto",
    textAlign: "left",
  },
  header: {
    width: "100%",
    textAlign: "center",
  },
  paragraph: {
    textAlign: "center",
    width: "100%",
  },
  cta: {
    display: "flex",
    margin: "2rem auto",
    alignItems: "center",
    justifyContent: "center",
  },
};
