import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { useNavigate, useLocation } from "react-router-dom";
import { SquareButton } from "../../common/Buttons/Buttons";
import { checkEmail, cleanEmail } from "../../../utils/formHelpers";
import { LocalStorageKeys } from "../../../utils/constants";
import { sendTwilioMagicLink } from "../../../utils/graphql";
import quotes from "../../../assets/images/quotes.webp";
import "./LoginPage.css";
import posthog from "posthog-js";
import { getEnvironment } from "../../../utils/environmentHelpers";
import { isAuthenticated, AuthStatus } from "../../../utils/auth";
import { getTransformPartnerJobs } from "../../../utils/graphql";

function LoginPage() {
  const navigate = useNavigate();

  const [email, setEmail] = useState(
    localStorage.getItem(LocalStorageKeys.email) || ""
  );
  const [submitting, setSubmitting] = useState(false);

  const location = useLocation();
  const state = location.state || {};
  const [error, setError] = useState(state.error || false);

  const handleInputChange = (event) => {
    setEmail(event.target.value);
  };

  const cleanUpEmail = () => {
    setEmail(cleanEmail(email));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (submitting) return;
    ReactGA.event({
      category: "cta_button",
      action: "click",
      label: "Next (Login Page)",
    });
    setSubmitting(true);
    setError(false);

    const cleanedEmail = cleanEmail(email);

    await sendTwilioMagicLink(cleanedEmail);

    localStorage.setItem(LocalStorageKeys.email, cleanedEmail);

    if (getEnvironment() === "production") {
      posthog.identify(cleanedEmail);
    }

    setSubmitting(false);
    navigate("/email-sent", { state: { data: email } });
  };

  const authedRedirect = async () => {
    const isOnboarded = localStorage.getItem(LocalStorageKeys.isOnboarded);

    const jobId = localStorage.getItem(LocalStorageKeys.jobId);
    if (jobId) {
      navigate("/pack-picker");
      return;
    }

    if (isOnboarded === "true") {
      navigate("/photoshoot-gallery");
    } else {
      // Check if user has any jobs, if so direct to gallery
      const email = localStorage.getItem(LocalStorageKeys.email);
      try {
        const fetchedJobs = await getTransformPartnerJobs(email);
        if (fetchedJobs.length > 0) {
          localStorage.setItem(LocalStorageKeys.isOnboarded, "true");
          navigate("/photoshoot-gallery");
          return;
        }
      } catch (error) {
        console.error("ERROR: ", error.message);
      }
      navigate("/tutorial");
    }
  };

  useEffect(() => {
    const checkIfAuthenticated = async () => {
      const emailParam = localStorage.getItem(LocalStorageKeys.email);
      const authStatus = await isAuthenticated(emailParam);

      if (authStatus === AuthStatus.AUTHENTICATED) {
        authedRedirect();
      }
    };

    checkIfAuthenticated();

    ReactGA.send({
      hitType: "pageview",
      page: "/login",
      title: "Login",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isEmailValid = checkEmail(email);

  return (
    <div className="loginContainer">
      <div className="leftSectionLogin">
        <h2 className="slideshowTitle">
          <span>Millions of Photos</span>
          <br />
          Created by Happy Customers
        </h2>
        <div id="slide-container">
          <div id="slide-first-element">
            <div className="slide-image"></div>
            <p className="slide-text">
              <img src={quotes} alt="" />
              Mine came out so good, looks just like her. Now I don't have to
              schedule photos!<span>- Sophia's mom, Ana C.</span>
            </p>
          </div>
          <div id="slide-element-2">
            <div className="slide-image"></div>
            <p className="slide-text">
              <img src={quotes} alt="" />
              😍😍😍😍😍😍😍😍😍😍😍
              <br />
              These are sooooo adorable!!!! I LOVEEEEE them!!!!!!!
              <span>- Nolan's mom, Leah B.</span>
            </p>
          </div>
          <div id="slide-element-3">
            <div className="slide-image"></div>
            <p className="slide-text">
              <img src={quotes} alt="" />
              Used it to make unicorn invites for my little girl's birthday. Too
              fun<span>- Charlotte's mom, Christina T.</span>
            </p>
          </div>
        </div>
      </div>
      <div className="rightSectionLogin">
        <h1>Login or sign up</h1>
        {error ? (
          <p className="errorMessage">
            Your verification link has expired.
            <br />
            Enter your email to receive a new link.
          </p>
        ) : (
          <p className="text">
            We'll send a login code to your email. No password needed.
          </p>
        )}
        <input
          type="email"
          id="email"
          name="email"
          value={email}
          onChange={handleInputChange}
          placeholder="example@gmail.com"
          className="emailInput"
          required
          onBlur={cleanUpEmail}
        />
        <SquareButton
          disabled={!isEmailValid || submitting}
          onClick={handleSubmit}
        >
          Continue
        </SquareButton>
        <p className="gray">
          By creating your account, you agree to our{" "}
          <a
            className="grayLink"
            href="https://joinhoneycomb.com/terms"
            target="_blank"
            rel="noreferrer"
          >
            Terms
          </a>{" "}
          and{" "}
          <a
            className="grayLink"
            href="https://joinhoneycomb.com/privacy"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
        </p>
      </div>
    </div>
  );
}

export default LoginPage;
