import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { useParams, useNavigate } from "react-router-dom";

import { fetchJobResultJson } from "../../../utils/fetchFile";
import PhotoGridView from "../../common/PhotoGrid/PhotoGridView";

import { SquareButton } from "../../common/Buttons/Buttons";
import { isMobile, isIOS } from "react-device-detect";
import {
  saveFiles,
  zipAndSaveFile,
  shareFilesIos,
} from "../../../utils/saveHelpers";
import { postTransformWebDownload } from "../../../utils/graphql";

function PhotoShootPage() {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/photoshoot",
      title: "Photoshoot",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { id } = useParams();

  const navigate = useNavigate();

  const [outputs, setOutputs] = useState([]);

  const [selectedItems, setSelectedItems] = useState([]);

  const [downloading, setDownloading] = useState(false);

  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(true);

  const handleItemClick = (imageSrc) => {
    setSelectedItems((prevSelectedItems) =>
      prevSelectedItems.includes(imageSrc)
        ? prevSelectedItems.filter((item) => item !== imageSrc)
        : [...prevSelectedItems, imageSrc]
    );
  };

  useEffect(() => {
    // Fetch the file when the component mounts
    const fetchData = async () => {
      const data = await fetchJobResultJson(id);

      const txt2imgOutputs = data?.outputs?.txt2img;
      const image2imgOutputs = data?.outputs?.img2img;

      const concatenatedOutputs = [...txt2imgOutputs, ...image2imgOutputs];

      setOutputs(concatenatedOutputs);
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }, [setLoading]);

  const downloadAndRedirect = async () => {
    setDownloading(true);

    ReactGA.event({
      category: "cta_button",
      action: "click",
      label: "Download",
    });

    // post transform download data
    postTransformWebDownload(id, selectedItems);

    if (isMobile || selectedItems.length === 1) {
      // use shareSheet to download on iOS to cameraroll
      if (isIOS) {
        await shareFilesIos(selectedItems);
      } else {
        await saveFiles(selectedItems);
      }
    } else {
      await zipAndSaveFile(selectedItems);
    }

    setTimeout(() => {
      navigate("/download-complete");
    }, 2000);
  };

  return (
    <div style={styles.container}>
      {/* TODO: populate with pack context, like pack name, from result.json? */}
      <h1>Your Photoshoot</h1>
      <p style={styles.text}>
        The quality of your photos largely depends on the images you uploaded.
        Select all your favorite photos to download in high resolution.
      </p>
      <PhotoGridView
        items={outputs}
        onItemClick={handleItemClick}
        selectedItems={selectedItems}
      />
      <div style={styles.bottomCopy}>
        <p>
          Not seeing any photos you love?{" "}
          <a
            style={styles.link}
            href="mailto:support@joinhoneycomb.com?subject=Photo%20Issue"
            target="_blank"
            rel="noreferrer"
          >
            Contact us.
          </a>
        </p>
      </div>
      <SquareButton
        disabled={selectedItems.length === 0 || downloading}
        onClick={downloadAndRedirect}
        style={styles.downloadCTA}
      >
        {downloading && <div style={styles.spinner}></div>}
        {!downloading ? "Download" : "Downloading..."}
      </SquareButton>
      <div style={styles.gradient}></div>
      <div style={styles.gradient}></div>
      {modalOpen ? (
        <div style={styles.overlay}>
          <div style={styles.modal}>
            <h2 style={styles.modalTitle}>How to select favorites</h2>
            <p>Here&apos;s how to get the most out of your photoshoot:</p>
            <p>
              ✅ <strong>Select your favorites</strong>
            </p>
            <p>
              Expect to find <strong>at least 3 photos</strong> you love -- but
              we let you see the entire batch so you can decide which ones to
              keep.
            </p>
            <p>
              ❌ <strong>Skip bad photos</strong>
            </p>
            <ul>
              <li>
                📸 In a <strong>traditional photoshoot</strong>, the photos to
                skip have closed eyes, blurry movement, or unflattering angles.
              </li>
              <li>
                ✨ In an <strong>instant photoshoot</strong>, skip photos with
                weird hands, strange backgrounds, or low resemblance.
              </li>
            </ul>
            <p>
              💡 The quality of your photos largely depends on your uploaded
              images. Great uploads = amazing photos.
            </p>
            <SquareButton
              style={styles.modalCTA}
              onClick={() => {
                ReactGA.event({
                  category: "cta_button",
                  action: "click",
                  label: "Select Favorites",
                });
                setModalOpen(false);
              }}
              disabled={!!loading}
            >
              {loading ? " Loading..." : "Select Favorites"}
            </SquareButton>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default PhotoShootPage;

const styles = {
  container: {
    position: "relative",
    paddingBottom: "100px",
  },
  downloadCTA: {
    position: "fixed",
    bottom: "2rem",
    left: "50%",
    transform: "translateX(-50%)",
    zIndex: 1000,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    textAlign: "center",
    maxWidth: "524px",
    margin: "1rem auto 2rem",
    padding: "0 1.5rem",
  },
  gradient: {
    width: "100vw",
    height: "100px",
    bottom: 0,
    zIndex: 2,
    position: "fixed",
    pointerEvents: "none",
    background:
      "linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%)",
  },
  modalCTA: {
    display: "flex",
    margin: "2rem auto",
    alignItems: "center",
    justifyContent: "center",
  },
  link: {
    color: "#333333",
  },
  bottomCopy: {
    maxWidth: "565px",
    margin: "0 auto",
    padding: "1.5rem 0 0",
  },
  overlay: {
    background: "rgba(255, 255, 255, 0.83)",
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 1001,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  modal: {
    background: "white",
    width: "80%",
    maxWidth: "553px",
    borderRadius: "20px",
    boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.25)",
    padding: "1rem 2rem",
    textAlign: "left",
  },
  spinner: {
    border: "4px solid rgba(0, 0, 0, 0.1)",
    borderLeftColor: "#333",
    borderRadius: "50%",
    width: "18px",
    height: "18px",
    animation: "spin 1s linear infinite",
    marginRight: "0.75rem",
    display: "inline-block",
  },
  modalTitle: {
    textAlign: "center",
  },
};
