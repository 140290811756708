import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import { useNavigate } from "react-router-dom";
import { useQuery } from "../../../utils/utmHelpers";

import { SquareButton } from "../../common/Buttons/Buttons";
import "./TutorialPage.css";

import landingStepNumbers from "../../../assets/images/landing_step_numbers.webp";
import landingStep1 from "../../../assets/images/landing_step_1.webp";
import landingStep2 from "../../../assets/images/landing_step_2.webp";
import landingStep3 from "../../../assets/images/landing_step_3.webp";
import landingNumber1 from "../../../assets/images/landing_number_1.webp";
import landingNumber2 from "../../../assets/images/landing_number_2.webp";
import landingNumber3 from "../../../assets/images/landing_number_3.webp";
import shield from "../../../assets/images/shield.webp";
import { LocalStorageKeys } from "../../../utils/constants";

function TutorialPage() {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/tutorial",
      title: "Tutorial",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const navigate = useNavigate();

  const query = useQuery();

  const navToPicker = () => {
    ReactGA.event({
      category: 'cta_button',
      action: 'click',
      label: 'Get Started',
    });

    navigate("/pack-picker");
  };

  useEffect(() => {
    const emailParam = query.get("email");
    if (emailParam) {
      localStorage.setItem(LocalStorageKeys.email, emailParam);
    }
  }, [query]);

  return (
    <div>
      <div>
        <h1 className="stepsHeader">How It Works</h1>
        <img
          src={landingStepNumbers}
          alt="3 Easy Steps"
          className="threeSteps"
        />
      </div>
      <div className="stepImageContainer">
        <img src={landingNumber1} alt="" className="stepNumber" />
        <div className="stepContainer">
          <p>Pick a photoshoot style</p>
          <img src={landingStep1} alt="Step 1" className="stepImage" />
        </div>
        <img src={landingNumber2} alt="" className="stepNumber" />
        <div className="stepContainer">
          <p>Upload a few photos</p>
          <img
            src={landingStep2}
            alt="Step 2"
            className="stepImage secondImage"
          />
        </div>
        <img src={landingNumber3} alt="" className="stepNumber" />
        <div className="stepContainer">
          <p className="thirdParagraph">
            We'll send you a unique photoshoot for you to choose your favorites
          </p>
          <img src={landingStep3} alt="Step 3" className="stepImage" />
        </div>
      </div>
      <p className="guarantee">
        <img src={shield} alt="" />
        100% Money Back Guarantee
      </p>
      <SquareButton onClick={navToPicker} className="cta">
        Get Started
      </SquareButton>
    </div>
  );
}

export default TutorialPage;
