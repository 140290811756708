import { LocalStorageKeys } from "./constants";
import { cleanEmail } from "./formHelpers";
import { getGraphqlUrl } from "./environmentHelpers";

export const AuthStatus = {
  AUTHENTICATED: "authenticated",
  UNAUTHENTICATED: "unauthenticated",
  EXPIRED: "expired",
};

export const isAuthenticated = async (email, token) => {
  // Check for existing authentication in local storage
  const userEmail = email || localStorage.getItem(LocalStorageKeys.email);
  const emailClean = cleanEmail(userEmail);

  const localAuthKey = `isAuthenticated-${emailClean}`;
  const prevAuth = localStorage.getItem(localAuthKey);
  if (prevAuth) {
    if (new Date().getTime() < prevAuth) {
      // valid session
      return AuthStatus.AUTHENTICATED;
    } else {
      localStorage.removeItem(localAuthKey); // Remove expired item
    }
  }

  const query = `
        mutation {
            verifyTwilioToken(
                email: "${emailClean}",
                token: "${token}",
            ) {
              message
              accessToken
              refreshToken
            }
        }
    `;

  try {
    const response = await fetch(getGraphqlUrl(), {
      // Updated URL here
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ query }),
    });

    const responseJson = await response.json();

    // console.log("responseJson: ", responseJson);

    if (responseJson.data) {
      const tokenResponse = responseJson.data.verifyTwilioToken;

      // console.log("tokenResponse: ", tokenResponse);

      if (tokenResponse) {
        if (tokenResponse.message === "Email verified successfully.") {
          const expiresAt = new Date().getTime() + 365 * 24 * 60 * 60 * 1000; // 1 year in milliseconds
          localStorage.setItem(localAuthKey, expiresAt);
          return AuthStatus.AUTHENTICATED;
        } else if (tokenResponse.message === "Invalid or expired token.") {
          return AuthStatus.EXPIRED;
        }
      }
    } else {
      return AuthStatus.UNAUTHENTICATED;
    }
  } catch (error) {
    console.error("Error submitting the GraphQL mutation:", error);
  }
};
