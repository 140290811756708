import "./App.css";
import { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ReactGA from "react-ga4";
import posthog from "posthog-js";

import AboutPage from "./components/pages/AboutPage/AboutPage";
import ContactPage from "./components/pages/ContactPage/ContactPage";
import PhotoShootPage from "./components/pages/PhotoShootPage/PhotoShootPage";
import NavBar from "./components/common/NavBar/NavBar";
import UploadPage from "./components/pages/UploadPage/UploadPage";
import LoginPage from "./components/pages/LoginPage/LoginPage";
import EmailSubmissionCompletePage from "./components/pages/EmailSubmissionCompletePage/EmailSubmissionCompletePage";
import RedirectPage from "./components/pages/RedirectPage/RedirectPage";
import TutorialPage from "./components/pages/TutorialPage/TutorialPage";
import PackPickerPage from "./components/pages/PackPickerPage/PackPickerPage";
import BabyProfilePage from "./components/pages/BabyProfilePage/BabyProfilePage";
import PaymentConfirmationPage from "./components/pages/PaymentConfirmationPage/PaymentConfirmationPage";
import PhotoshootGalleryPage from "./components/pages/PhotoshootGalleryPage/PhotoshootGalleryPage";
import AuthRedirectPage from "./components/pages/AuthRedirectPage/AuthRedirectPage";
import DownloadCompletePage from "./components/pages/DownloadCompletePage/DownloadCompletePage";
import PricingPage from "./components/pages/PricingPage/PricingPage";
import TimeoutPage from "./components/pages/TimeoutPage/TimeoutPage";
import { LocalStorageKeys } from "./utils/constants";
import CheckoutPage from "./components/pages/PaymentPage/CheckoutPage";
import { getEnvironment } from "./utils/environmentHelpers";
import { getTransformPartnerJobs } from "./utils/graphql";
import { v4 as uuidv4 } from "uuid";
import PostHogPageView from "./utils/posthogPageView";

function App() {
  ReactGA.initialize("G-8R7P008CB5");

  const email = localStorage.getItem(LocalStorageKeys.email) || "";
  const prevAuth =
    localStorage.getItem(`isAuthenticated-${email}`) &&
    new Date().getTime() < localStorage.getItem(`isAuthenticated-${email}`)
      ? true
      : false;
  const [authenticated, setAuthenticated] = useState(prevAuth);

  const useUTMParams = () => {
    useEffect(() => {
      const urlParams = new URLSearchParams(window.location.search);
      const utmCampaign = urlParams.get("utm_campaign");
      const utmSource = urlParams.get("utm_source");
      const utmMedium = urlParams.get("utm_medium");
      const utmEmail = urlParams.get("utm_email");

      if (utmCampaign) {
        localStorage.setItem("utm_campaign", utmCampaign);
      }
      if (utmSource) {
        localStorage.setItem("utm_source", utmSource);
      }
      if (utmMedium) {
        localStorage.setItem("utm_medium", utmMedium);
      }
      if (utmEmail) {
        localStorage.setItem(LocalStorageKeys.email, utmEmail);
      }
    }, []);
  };
  useUTMParams();

  useEffect(() => {
    if (getEnvironment() !== "production") {
      return;
    }
    // Retrieve email from localStorage (or wherever it is stored)
    const email = localStorage.getItem(LocalStorageKeys.email);

    if (
      email &&
      email !== "" &&
      email !== null &&
      email !== "null" &&
      email !== undefined
    ) {
      posthog.identify(email);
    }
  }, []);

  useEffect(() => {
    // if somehow a user has created a job with a jobId but it is still in the localStorage, replace it with a new one
    const checkForUsedJobID = async () => {
      const jobId = localStorage.getItem(LocalStorageKeys.jobId);
      if (jobId) {
        const email = localStorage.getItem(LocalStorageKeys.email);

        try {
          const fetchedJobs = await getTransformPartnerJobs(email);
          const jobExists = fetchedJobs.some((job) => job.uuid === jobId);

          if (jobExists) {
            const newJobId = uuidv4();
            localStorage.setItem(LocalStorageKeys.jobId, newJobId);
            localStorage.removeItem(LocalStorageKeys.trainingImages);
            console.log("Creating new jobId", newJobId);
          }
        } catch (error) {
          console.error("Error fetching jobs:", error);
        }
      }
    };

    checkForUsedJobID();
  }, []);

  return (
    <Router>
      <PostHogPageView />
      <div className="App">
        <NavBar
          isAuthenticated={authenticated}
          setAuthenticated={setAuthenticated}
        />
        <Routes>
          {/* Main flow */}
          <Route
            path="/"
            element={<AuthRedirectPage setAuthenticated={setAuthenticated} />}
          />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/email-sent" element={<EmailSubmissionCompletePage />} />
          {authenticated &&
            localStorage.getItem(LocalStorageKeys.email) !== "" &&
            localStorage.getItem(LocalStorageKeys.email) !== "null" &&
            localStorage.getItem(LocalStorageKeys.email) !== null &&
            localStorage.getItem(LocalStorageKeys.email) !== undefined && (
              <>
                <Route path="/upload" element={<UploadPage />} />
                <Route path="/photoshoot/:id" element={<PhotoShootPage />} />
                <Route path="/redirect" element={<RedirectPage />} />
                <Route path="/tutorial" element={<TutorialPage />} />
                <Route path="/pack-picker" element={<PackPickerPage />} />
                <Route path="/baby-profile" element={<BabyProfilePage />} />
                <Route path="/pricing" element={<PricingPage />} />
                <Route path="/timeout" element={<TimeoutPage />} />
                <Route path="/checkout" element={<CheckoutPage />} />
                <Route
                  path="/payment-confirmation"
                  element={<PaymentConfirmationPage />}
                />
                <Route
                  path="/photoshoot-gallery"
                  element={<PhotoshootGalleryPage />}
                />
                <Route
                  path="/download-complete"
                  element={<DownloadCompletePage />}
                />
              </>
            )}
          {/* Additional pages */}
          <Route path="/about" element={<AboutPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="*" element={<AuthRedirectPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
