export const checkEmail = (email) => {
  // Regular expression to check for a valid email format
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(email);
};

export const cleanEmail = (email) => {
  if (
    email === "null" ||
    email === null ||
    email === undefined ||
    email === ""
  ) {
    return;
  }
  // remove trailing period (sometimes added in utm param)
  const emailNoTrailingPeriod = email.endsWith(".")
    ? email.slice(0, -1)
    : email;

  // remove any +, ie. amelia+test@joinhoneycomb.com
  const emailNoNameAddon = emailNoTrailingPeriod.replace(/(\+[^@]*)@/, "@");

  // make lowercase to keep one user per email
  const emailLower = emailNoNameAddon.toLowerCase();
  return emailLower;
};
