import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";

function EmailSubmissionCompletePage() {
  useEffect(() => {
  ReactGA.send({
    hitType: "pageview",
    page: "/email-sent",
    title: "Email Submission Complete",
  });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const location = useLocation();
  const { data: email } = location.state || {};

  return (
    <div style={styles.container}>
      <h1 style={styles.header}>Check your email</h1>
      <p>A sign in link has been sent to your email address:</p>
      <p><strong>{email}</strong></p>
      <p>This link will expire in 10 minutes. Make sure to check your spam folder.</p>
    </div>
  );
}

export default EmailSubmissionCompletePage;

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    flexWrap: "wrap",
    maxWidth: "363px",
    margin: "0 auto",
    padding: "0 1.5rem",
    textAlign: "center",
  },
};
