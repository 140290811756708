import s3 from "./awsConfig";

import { fileTypeFromBlob } from "file-type";
import heic2any from "heic2any";
import { resizeImage } from "./imageUtils";
import {
  partnerId,
  heicExtensions,
  uploadErrorMessages,
  LocalStorageKeys,
} from "./constants";
import { getEnvironment } from "./environmentHelpers";
// import axios from "axios";

export const deleteFileFromS3 = async (file, jobId) => {
  let filePath = file.path;
  const originalExtension = filePath.split(".").pop().toLowerCase();

  // if HEIC / HEIF, remove that from s3 instead of original extension
  if (heicExtensions.includes(originalExtension)) {
    filePath = filePath.replace(/\.[^.]+$/, ".png"); // Replace extension with .png
  }

  const environment = getEnvironment();

  const key = `transform/${environment}/${partnerId}/${jobId}/training_set/${filePath}`;

  const params = {
    Bucket: "honeycomb-submissions",
    Key: key, // File path within the bucket
  };

  try {
    await s3.deleteObject(params).promise();
    // console.log("File deleted successfully from S3:", filePath);
  } catch (error) {
    // console.error("Error deleting file from S3:", error);
    // TODO:  Handle errors appropriately, e.g., display error message to user
  }
};

// Function to upload file directly to S3
// Handles conversion from HEIF/HEIC to PNG and resizing
export const uploadToS3 = async (
  originalFile,
  jobId,
  updateFileStatus,
  updateFilePreviewAndName,
  setErrorMessage
  // load,
  // error,
  // progress,
) => {
  const startTime = new Date(); // Record start time
  let fileToUpload = originalFile;
  try {
    fileToUpload = await prepareFileForUpload(
      originalFile,
      updateFileStatus,
      updateFilePreviewAndName,
      startTime
    );
  } catch (error) {
    setErrorMessage(uploadErrorMessages.uploadFailed);
    updateFileStatus(originalFile, "failed");
    console.error("Error preparing file", error);
  }

  const environment = getEnvironment();
  const key = `transform/${environment}/${partnerId}/${jobId}/training_set/${fileToUpload.name}`;

  const params = {
    Bucket: "honeycomb-submissions",
    Key: key,
    Body: fileToUpload,
    ContentType: fileToUpload.type,
  };

  const options = {
    partSize: 5 * 1024 * 1024, // 5MB
    queueSize: 1,
    onUploadProgress: (evt) => {
      // progress(evt.loaded, evt.total);
    },
  };

  s3.upload(params, options)
    .promise()
    .then((data) => {
      // const endUploadTime = new Date(); // Record end time after successful upload
      // const durationInSeconds = (endUploadTime - startTime) / 1000; // Calculate duration in seconds
      // console.log(`Upload took ${durationInSeconds} seconds`);
      // console.log("File uploaded successfully", data);

      // update original file, not the fileToUpload, to update UI
      updateFileStatus(originalFile, "completed");
      localStorage.setItem(LocalStorageKeys.previewImageUrl, data.Location);

      const newFilename = originalFile.name.replace(/\.\w+$/, ".png");
      const newFiletype = "image/png";

      updateFilePreviewAndName(
        originalFile,
        data.Location,
        newFilename,
        newFiletype
      );
    })
    .catch((uploadError) => {
      setErrorMessage(uploadErrorMessages.uploadFailed);
      updateFileStatus(originalFile, "failed");
      console.error("Error uploading file", uploadError);
    });
};

const prepareFileForUpload = async (
  originalFile,
  updateFileStatus,
  updateFilePreviewAndName,
  startTime
) => {
  let fileToUpload = originalFile;
  // Convert HEIC/HEIF files to JPEG format before upload

  // Detect the file type
  const fileType = await fileTypeFromBlob(originalFile);
  const detectedExtension = fileType ? fileType.ext : null;
  const detectedMimeType = fileType ? fileType.mime : null;

  // Rename the file if the extension is incorrect
  if (
    detectedExtension &&
    !originalFile.name.endsWith(`.${detectedExtension}`)
  ) {
    const correctedFilename = originalFile.name.replace(
      /\.\w+$/,
      `.${detectedExtension}`
    );
    fileToUpload = new File([originalFile], correctedFilename, {
      type: detectedMimeType,
    });
    console.log(`Renamed file to: ${correctedFilename}`);
  }

  const isHeicOrHeif =
    fileType && (fileType.ext === "heic" || fileType.ext === "heif");

  if (isHeicOrHeif) {
    try {
      const blob = await heic2any({ blob: originalFile });
      const newFilename = originalFile.name.replace(/\.\w+$/, ".png");
      const newFiletype = "image/png";
      const convertedPng = new File([blob], newFilename, {
        type: newFiletype,
      });
      // Resize image
      try {
        fileToUpload = await resizeImageForUpload(convertedPng);
      } catch (resizeError) {
        console.error("Error resizing image:", resizeError);
        updateFileStatus(originalFile, "failed");
        return;
      }
      const updatedPreview = URL.createObjectURL(fileToUpload);
      // Update UI
      updateFilePreviewAndName(
        originalFile,
        updatedPreview,
        newFilename,
        newFiletype
      );
      // const endConversionTime = new Date();
      // const durationInSeconds = (endConversionTime - startTime) / 1000; // Calculate duration in seconds
      // console.log(`Conversion took ${durationInSeconds} seconds`);
    } catch (conversionError) {
      console.error("Error converting HEIC/HEIF to JPEG:", conversionError);
      // update original file, not the fileToUpload, to update UI
      updateFileStatus(originalFile, "failed");
      return;
    }
  } else {
    try {
      fileToUpload = await resizeImageForUpload(fileToUpload);
    } catch (resizeError) {
      console.error("Error resizing image:", resizeError);
      updateFileStatus(originalFile, "failed");
      return;
    }
  }
  return fileToUpload;
};

const resizeImageForUpload = async (fileToResize) => {
  const maxWidth = 1600;
  const maxHeight = 1600;

  // Check if resizing is necessary
  if (fileToResize.width < maxWidth && fileToResize.height < maxHeight) {
    return fileToResize;
  }

  const resizedBlob = await resizeImage(fileToResize, maxWidth, maxHeight);

  return (fileToResize = new File([resizedBlob], fileToResize.name, {
    type: fileToResize.type,
    lastModified: Date.now(),
  }));
};

// // Upload file to S3
// async function uploadFile(file) {
//   const formData = new FormData();
//   formData.append("file", file);

//   try {
//     const response = await axios.post(
//       "http://app.joinhoneycomb.com/api/upload/",
//       formData,
//       {
//         headers: {
//           Authorization: `Bearer ${yourAccessToken}`, // Add your JWT token here
//           "Content-Type": "multipart/form-data",
//         },
//       }
//     );
//     console.log("File uploaded successfully:", response.data.message);
//   } catch (error) {
//     console.error("Error uploading file:", error.response.data.error);
//   }
// }

// // Delete file from S3
// async function deleteFile(key) {
//   try {
//     const response = await axios.delete(
//       "http://app.joinhoneycomb.com/api/delete/",
//       {
//         headers: {
//           Authorization: `Bearer ${yourAccessToken}`, // Add your JWT token here
//         },
//         data: { key },
//       }
//     );
//     console.log("File deleted successfully:", response.data.message);
//   } catch (error) {
//     console.error("Error deleting file:", error.response.data.error);
//   }
// }
