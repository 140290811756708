import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "../../../utils/utmHelpers";
import { AuthStatus, isAuthenticated } from "../../../utils/auth";
import { LocalStorageKeys } from "../../../utils/constants";
import {
  getTransformPartnerJobs,
  getTransformPartnerJob,
} from "../../../utils/graphql";

function AuthRedirectPage({ setAuthenticated }) {
  const navigate = useNavigate();

  const query = useQuery();

  const isAuthenticating = useRef(false);

  const authedRedirect = async () => {
    const isOnboarded = localStorage.getItem(LocalStorageKeys.isOnboarded);

    const jobId = localStorage.getItem(LocalStorageKeys.jobId);
    if (jobId) {
      navigate("/pack-picker");
      return;
    }

    if (isOnboarded === true) {
      navigate("/photoshoot-gallery");
    } else {
      // Check if user has any jobs, if so direct to gallery
      const email = localStorage.getItem(LocalStorageKeys.email);
      try {
        const fetchedJobs = await getTransformPartnerJobs(email);
        if (fetchedJobs.length > 0) {
          localStorage.setItem(LocalStorageKeys.isOnboarded, true);
          navigate("/photoshoot-gallery");
          return;
        }
      } catch (error) {
        console.error("ERROR: ", error.message);
      }
      navigate("/tutorial");
    }
  };

  // Handle magic link authentication
  useEffect(() => {
    if (isAuthenticating.current === true) {
      return;
    } else {
      isAuthenticating.current = true;
    }
    const emailParam =
      localStorage.getItem(LocalStorageKeys.email) ||
      query.get("email") ||
      undefined;
    const tokenParam = query.get("token") || undefined;
    const jobIdParam = query.get("jobId") || undefined;

    const fetchJob = async () => {
      if (emailParam && jobIdParam) {
        try {
          const fetchedJob = await getTransformPartnerJob(
            emailParam,
            jobIdParam
          );

          const parsedTrainingImages = JSON.parse(fetchedJob.trainingImages);
          const stringifiedTrainingImages =
            JSON.stringify(parsedTrainingImages);

          localStorage.setItem(LocalStorageKeys.babyName, fetchedJob.babyName);
          localStorage.setItem(LocalStorageKeys.gender, fetchedJob.gender);
          localStorage.setItem(
            LocalStorageKeys.previewImageUrl,
            fetchedJob.previewImage
          );
          localStorage.setItem(
            LocalStorageKeys.trainingImages,
            stringifiedTrainingImages
          );
          localStorage.setItem(LocalStorageKeys.jobId, fetchedJob.uuid);
          localStorage.setItem(LocalStorageKeys.packId, fetchedJob.packId);
          localStorage.setItem(LocalStorageKeys.packName, fetchedJob.packName);
        } catch (error) {
          // setError(error.message);
        }
      }
    };

    const checkAuthentication = async () => {
      try {
        const authStatus = await isAuthenticated(emailParam, tokenParam);

        if (authStatus === AuthStatus.AUTHENTICATED) {
          setAuthenticated(true);
          authedRedirect();
        } else if (authStatus === AuthStatus.EXPIRED) {
          navigate("/login", {
            state: { error: true },
          });
        } else {
          // TODO:  This is kinda redundant, but not capturing expired state above as expected
          navigate("/login", {});
        }
      } catch (error) {
        console.error("Error during authentication:", error);
        navigate("/login");
      }
    };

    const authenticateAndFetchJob = async () => {
      await fetchJob();
      await checkAuthentication();
    };

    authenticateAndFetchJob();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={styles.container}>
      <h1>Verifying your email...</h1>
      <div style={styles.spinner}></div>
    </div>
  );
}

export default AuthRedirectPage;

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 1.5rem",
    maxWidth: "320px",
    margin: "0 auto",
  },
  spinner: {
    border: "4px solid rgba(0, 0, 0, 0.1)",
    borderLeftColor: "#333",
    borderRadius: "50%",
    width: "40px",
    height: "40px",
    animation: "spin 1s linear infinite",
    marginBottom: "20px",
    marginTop: "20px",
  },
};
