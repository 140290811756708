import React, { useState } from "react";
import "./FeedbackModal.css";
import { SquareButton } from "../../common/Buttons/Buttons";
import { useNavigate } from "react-router-dom";

const FeedbackModal = () => {
  const [rating, setRating] = useState(null); // 1-5 star rating
  const [showDetails, setShowDetails] = useState(false); // controls details display
  const [selectedOptions, setSelectedOptions] = useState([]); // for checkboxes
  const [otherFeedback, setOtherFeedback] = useState(""); // general feedback text area
  const [otherOption, setOtherOption] = useState(""); // text input for 'Other' option
  const [isTransitioning, setIsTransitioning] = useState(false); // prevent multiple clicks

  const navigate = useNavigate(); // Checkbox options

  const options = [
    "Mis-shaped limbs",
    "Unnatural eyes",
    "Skin tone",
    "Overall likeness",
    "Other",
  ];

  // Handle rating selection
  const handleRatingSelect = (ratingValue) => {
    if (!isTransitioning) {
      setRating(ratingValue);
      setIsTransitioning(true);

      setTimeout(() => {
        setShowDetails(true);
        setIsTransitioning(false);
      }, 500);
    }
  };

  // Handle checkbox selection
  const handleCheckboxChange = (option) => {
    if (option === "Other") {
      // When "Other" is selected, toggle its presence in selectedOptions
      if (selectedOptions.includes("Other")) {
        setSelectedOptions((prev) => prev.filter((o) => o !== option));
        setOtherOption(""); // Clear the text box when unselecting "Other"
      } else {
        setSelectedOptions((prev) => [...prev, option]);
      }
    } else {
      setSelectedOptions((prev) =>
        prev.includes(option)
          ? prev.filter((o) => o !== option)
          : [...prev, option]
      );
    }
  };

  // Handle feedback submission
  const handleSubmit = () => {
    // const feedback = {
    //   rating,
    //   selectedOptions,
    //   otherOption: otherOption ? otherOption : null, // Include 'Other' text only if filled in
    //   otherFeedback,
    // };
    // console.log("Feedback submitted: ", feedback);
    // onClose();
    // downloadCompleteRedirect();

    navigate("/photoshoot-gallery");
  };

  const isOpen = true;

  if (!isOpen) return null; // Don't render the modal if it's not open

  // Prevent the modal from closing when clicking inside the modal content
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      // onClose();
    }
  };

  return (
    <div className="modal-overlay" onClick={handleOverlayClick}>
      <div className="modal-content">
        {/* Close button */}
        {/* <button className="close-button" onClick={onClose}>
          &times;
        </button> */}
        {!showDetails ? (
          <>
            <h2>How was your photoshoot?</h2>
            <div className="rating">
              {[1, 2, 3, 4, 5].map((star) => (
                <span
                  key={star}
                  className={`star ${rating >= star ? "selected" : ""}`}
                  onClick={() => handleRatingSelect(star)}
                  style={{ pointerEvents: isTransitioning ? "none" : "auto" }}
                >
                  ★
                </span>
              ))}
            </div>
          </>
        ) : (
          <>
            <div className="left-align">
              <h2>Thank you for your rating!</h2>
              <p className="modal-feedback-subheading">Any Issues?</p>
              <div className="options-grid">
                {options.map((option) => (
                  <div key={option} className="option-item">
                    <label className="option-label">
                      <input
                        type="checkbox"
                        checked={selectedOptions.includes(option)}
                        onChange={() => handleCheckboxChange(option)}
                      />
                      {/* Conditionally hide the label text for the "Other" option */}
                      {option !== "Other" || !selectedOptions.includes(option)
                        ? option
                        : ""}
                    </label>
                    {option === "Other" &&
                      selectedOptions.includes("Other") && (
                        <input
                          type="text"
                          className="other-input"
                          value={otherOption}
                          onChange={(e) => setOtherOption(e.target.value)}
                          placeholder="Please specify"
                        />
                      )}
                  </div>
                ))}
              </div>
            </div>

            <div className="feedback">
              <label>
                <p className="modal-feedback-subheading">
                  Additional Feedback:
                </p>
                <textarea
                  value={otherFeedback}
                  className="additional-feedback"
                  onChange={(e) => setOtherFeedback(e.target.value)}
                  rows="4"
                  cols="50"
                  placeholder="Enter your feedback here..."
                />
              </label>
            </div>

            {/* <button onClick={handleSubmit}>Submit Feedback</button> */}
            <div className="submit-cta-container">
              <SquareButton className="submit-cta" onClick={handleSubmit}>
                Submit Feedback
              </SquareButton>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default FeedbackModal;
