import React, { useState } from "react";
import { SquareButton } from "../../common/Buttons/Buttons";
import ReactGA from "react-ga4";
import { pricingButtonType } from "./PricingPage";
import { useNavigate } from "react-router-dom";
import { updateTransformPartnershipJob } from "../../../utils/graphql";

function PricingItem({ item }) {
  const [submitting, setSubmitting] = useState(false);

  const navigate = useNavigate();

  const handleCreateJob = async (item) => {
    if (submitting) return;
    try {
      await updateTransformPartnershipJob("PENDING", item.returnedOutputs);

      // TODO: block redirect if updateJob fails

      navigate("/checkout", { state: { priceId: item.priceId } });
    } catch (error) {
      console.error("Error creating partnership job:", error);
      navigate("/login");

      // TODO: Handle error appropriately (e.g., show error message to user)
    }

    setSubmitting(false);
  };

  const handleContactUs = () => {
    window.open(
      "https://joinhoneycomb.com/custom-request",
      "_blank",
      "rel=noreferrer"
    );

    setSubmitting(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (submitting) return;
    ReactGA.event({
      category: "cta_button",
      action: "click",
      label: "Next (Pricing Page)",
    });
    setSubmitting(true);

    if (item.button === pricingButtonType.SELECT) {
      handleCreateJob(item);
    } else if (item.button === pricingButtonType.CONTACT_US) {
      handleContactUs();
    }
  };

  return (
    <div className={item.containerStyle} onClick={handleSubmit}>
      <p className="pricingTitle">{item.title}</p>
      <p className="pricingStrike">{item.strikePrice}</p>
      <p
        className={`pricingPrice ${
          item.button === pricingButtonType.CONTACT_US ? "contact" : ""
        }`}
      >
        {item.price}
      </p>
      <SquareButton onClick={handleSubmit}>
        {item.button === pricingButtonType.CONTACT_US ? "Contact us" : "Select"}
      </SquareButton>
      <ul>
        {item.features.map((feature, index) => (
          <li className="bigOnly" key={index}>
            {feature}
          </li>
        ))}
        {item.featuresMobile.map((feature, index) => (
          <li className="smallOnly" key={index}>
            {feature}
          </li>
        ))}
        {item.turnAroundTime && (
          <li>
            {item.turnAroundTime}{" "}
            <span className="bigOnly">turnaround time</span>
            <span className="smallOnly">eta</span>
          </li>
        )}
      </ul>
    </div>
  );
}

export default PricingItem;
