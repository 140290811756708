import newborn from "../../../assets/images/newborn-pack.webp";
import variety from "../../../assets/images/variety-pack.webp";
import birthday from "../../../assets/images/birthday-pack.webp";
import royals from "../../../assets/images/royals-pack.webp";
import flowers from "../../../assets/images/flower-pack.webp";
import jedi from "../../../assets/images/jedi-pack.webp";
import graduation from "../../../assets/images/graduation-pack.webp";
import astronaut from "../../../assets/images/astronaut-pack.webp";
import usa from "../../../assets/images/usa-pack.webp";
import aviator from "../../../assets/images/aviator-pack.webp";
import halloween from "../../../assets/images/halloween-pack.webp";
import christmas from "../../../assets/images/christmas-pack.webp";
import valentines from "../../../assets/images/valentines-pack.webp";
import dragonyear from "../../../assets/images/dragonyear-pack.webp";
import easter from "../../../assets/images/easter-pack.webp";
import stpattys from "../../../assets/images/stpattys-pack.webp";

export const packs = [
  { id: "newborn", title: "Newborn", image: newborn },
  { id: "variety", title: "Variety Pack", image: variety },
  { id: "birthday", title: "Birthday", image: birthday },
  { id: "royals", title: "Royals", image: royals },
  { id: "flowers", title: "Flowers", image: flowers },
  { id: "jedi", title: "Jedi", image: jedi },
  { id: "graduation", title: "Graduation", image: graduation },
  { id: "astronaut", title: "Astronaut", image: astronaut },
  { id: "usa", title: "USA", image: usa },
  { id: "aviator", title: "Aviator", image: aviator },
  { id: "halloween", title: "Halloween", image: halloween },
  { id: "xmas_santa", title: "Christmas", image: christmas },
  { id: "valentines", title: "Valentines", image: valentines },
  { id: "lunar_new_year", title: "Dragon Year", image: dragonyear },
  { id: "easter", title: "Easter", image: easter },
  { id: "st_pattys", title: "St. Patty's", image: stpattys },
];
