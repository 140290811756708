import { partnerId, LocalStorageKeys } from "./constants";
import { getGraphqlUrl } from "./environmentHelpers";

// MUTATIONS

export const sendTwilioMagicLink = async (email) => {
  const utmCampaign = localStorage.getItem("utm_campaign") || "";
  const utmSource = localStorage.getItem("utm_source") || "";
  const utmMedium = localStorage.getItem("utm_medium") || "";

  const query = `
      mutation {
          sendTwilioMagicLink(
              email: "${email}",
              utmCampaign: "${utmCampaign}",
              utmSource: "${utmSource}",
              utmMedium: "${utmMedium}",
          ) {
            message
          }
      }
  `;

  try {
    await graphqlFetch(query);
  } catch (error) {
    console.error("Error submitting the GraphQL mutation:", error);
  }
};

export const createTransformPartnershipJob = async () => {
  const jobId = localStorage.getItem(LocalStorageKeys.jobId);
  const packId = localStorage.getItem(LocalStorageKeys.packId);
  const packName = localStorage.getItem(LocalStorageKeys.packName);
  const email = localStorage.getItem(LocalStorageKeys.email);
  const gender = localStorage.getItem(LocalStorageKeys.gender);
  const babyName = localStorage.getItem(LocalStorageKeys.babyName);
  const previewImage = localStorage.getItem(LocalStorageKeys.previewImageUrl);
  const trainingImages = JSON.stringify(
    localStorage.getItem(LocalStorageKeys.trainingImages)
  );

  if (email === null || email === undefined || email === "null") {
    console.error("Email is missing from local storage: ", email);
    console.error(
      "\nJobId: ",
      jobId,
      "NpackId: ",
      packId,
      "NpackName: ",
      packName,
      "email: ",
      email,
      "gender: ",
      gender,
      "\babyName: ",
      babyName,
      "previewImage: ",
      previewImage
    );
    return false;
  }

  const query = `
        mutation {
            createTransformPartnershipJob(
                uuid: "${jobId}",
                packId: "${packId}",
                packName: "${packName}",
                email: "${email}",
                partner: "${partnerId}",
                gender: "${gender}",
                babyName: "${babyName}",
                previewImage: "${previewImage}",
                trainingImages: ${trainingImages} 
            ) {
              createTransformPartnershipJob
            }
        }
    `;

  try {
    const result = await graphqlFetch(query);
    const responseJson = await result.json();

    if (
      responseJson.data.createTransformPartnershipJob
        .createTransformPartnershipJob === "ok"
    ) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error("Error submitting the GraphQL mutation:", error);
  }
};

export const updateTransformPartnershipJob = async (
  jobStatus,
  returnCount = 50
) => {
  const jobId = localStorage.getItem(LocalStorageKeys.jobId);
  const gender = localStorage.getItem(LocalStorageKeys.gender);
  const babyName = localStorage.getItem(LocalStorageKeys.babyName);
  const previewImage = localStorage.getItem(LocalStorageKeys.previewImageUrl);
  const trainingImages = JSON.stringify(
    localStorage.getItem(LocalStorageKeys.trainingImages)
  );

  const query = `
        mutation {
            updateTransformPartnershipJob(
                uuid: "${jobId}",
                gender: "${gender}",
                babyName: "${babyName}",
                previewImage: "${previewImage}",
                jobStatus: "${jobStatus}",
                returnCount: ${returnCount},
                trainingImages: ${trainingImages} 
            ) {
              updateTransformPartnershipJob
            }
        }
    `;

  try {
    await graphqlFetch(query);
  } catch (error) {
    console.error("Error submitting the GraphQL mutation:", error);
  }
};

export const executeTransformPartnershipJob = async (jobId) => {
  const query = `
        mutation {
            executeTransformPartnershipJob(
                uuid: "${jobId}",
            ) {
              executeTransformPartnershipJob
            }
        }
    `;

  try {
    await graphqlFetch(query);
  } catch (error) {
    console.error("Error submitting the GraphQL mutation:", error);
  }
};

export const postTransformWebDownload = async (jobId, downloadData) => {
  const simplifiedData = downloadData.map((item) => ({
    pack_id: item.pack_id,
    prompt_id: item.prompt_id,
  }));

  const downloadsJson = { downloads: simplifiedData };

  const downloadDataString = JSON.stringify(downloadsJson).replace(/"/g, '\\"');

  const query = `
    mutation {
      postTransformWebDownload(
        jobId: "${jobId}", 
        downloadData: "${downloadDataString}"
      ) {
        webDownload {
          id
        }
      }
    }
  `;

  try {
    const response = await graphqlFetch(query);

    const result = await response.json();

    return result.data.postTransformWebDownload.webDownload;
  } catch (error) {
    console.error("Error submitting the GraphQL mutation:", error);
    throw error;
  }
};

// QUERIES

export const getTransformPartnerJobs = async (email) => {
  const query = `
    query {
      transformPartnerJobs(
        email: "${email}",
        partner: "${partnerId}",
      ) {
        transformPartnershipJobs {
          uuid
          babyName
          babyBirthday
          previewImage
          jobStatus
          packName
          createdAt
        }
      }
    }
  `;

  try {
    const response = await graphqlFetch(query);

    const result = await response.json();
    if (result.errors) {
      throw new Error(result.errors[0].message);
    }
    return result.data.transformPartnerJobs.transformPartnershipJobs;
  } catch (error) {
    console.error("Error fetching transform partner jobs:", error);
    throw error;
  }
};

export const getTransformPartnerJob = async (email, jobId) => {
  const query = `
    query {
      transformPartnerJob(
        email: "${email}",
        jobId: "${jobId}",
      ) {
        uuid
        babyName
        babyBirthday
        previewImage
        jobStatus
        packName
        packId
        gender
        trainingImages
        createdAt
      }
    }
  `;

  try {
    const response = await graphqlFetch(query);

    const result = await response.json();
    if (result.errors) {
      throw new Error(result.errors[0].message);
    }
    return result.data.transformPartnerJob;
  } catch (error) {
    console.error("Error fetching transform partner job:", error);
    throw error;
  }
};

// UTILS

const graphqlFetch = async (query) => {
  return await fetch(getGraphqlUrl(), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ query }),
  });
};
