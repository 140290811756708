import { getEnvironment } from "./environmentHelpers";

export const partnerId = "honeycomb";

export const POSTHOG_KEY = "phc_R5q4E6ApABVZuQSONoL7ZyK8mZOrsmE9FbfSXJjhMPg";
export const POSTHOG_HOST = "https://us.i.posthog.com";

export const heicExtensions = ["heic", "HEIC", "heif", "HEIF"];

export const uploadErrorMessages = {
  duplicateFile: {
    text: "⛔️ You've already uploaded this photo",
    color: "red",
  },
  fileFormat: {
    text: "⛔ Accepted file formats: JPG, PNG, HEIC",
    color: "red",
  },
  uploadFailed: {
    text: "⛔ Upload failed, please try again",
    color: "red",
  },
  maxUploads: {
    text: "👍 All set, you've already uploaded 10 photos",
    color: "green",
  },
};

export const LocalStorageKeys = {
  jobId: "jobId",
  packId: "packId",
  packName: "packName",
  email: "email",
  partner: "partner",
  gender: "gender",
  babyName: "babyName",
  birthdate: "birthdate",
  previewImageUrl: "previewImageUrl",
  trainingImages: "trainingImages",
};

const stagingPaymentLinks = {
  dollar10: "https://buy.stripe.com/bIY7tg4Cde6r2yIbIL",
  dollar15: "https://buy.stripe.com/14keVI2u58M7ehq8wA",
};

const productionPaymentLinks = {
  dollar10: "https://buy.stripe.com/fZefZM1q17I33CMdQV",
  dollar15: "https://buy.stripe.com/eVafZM9Wx7I3gpy28e",
};

export const getPaymentLinks = () => {
  return getEnvironment() === "production"
    ? productionPaymentLinks
    : stagingPaymentLinks;
};
