import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LocalStorageKeys } from "../../../utils/constants";
import { getTransformPartnerJobs } from "../../../utils/graphql";

import honeycombTransformsHeaderLogo from "../../../assets/brand/honeycomb_logo.webp";
import "./NavBar.css";

import HamburgerMenu from "./HamburgerMenu";
import { getEnvironment } from "../../../utils/environmentHelpers";

const progressBarPercentages = {
  "/pack-picker": "10%",
  "/upload": "50%",
  "/baby-profile": "90%",
};
/* eslint-disable no-unused-vars */
const emptyTODOremoveChange = () => [];

function NavBar({ isAuthenticated, setAuthenticated }) {
  // hack for making sure page is scrolled to top when you switch pages
  let location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0.1); // this time out is needed for when using the back button for some reason
  }, [location]);

  const navigate = useNavigate();

  const environment = getEnvironment();

  const galleryOrTutorial = async () => {
    const isOnboarded = localStorage.getItem(LocalStorageKeys.isOnboarded);
    if (isOnboarded === true) {
      navigate("/photoshoot-gallery");
    } else {
      // Check if user has any jobs, if so direct to gallery
      const email = localStorage.getItem(LocalStorageKeys.email);
      try {
        const fetchedJobs = await getTransformPartnerJobs(email);
        if (fetchedJobs.length > 0) {
          localStorage.setItem(LocalStorageKeys.isOnboarded, true);
          navigate("/photoshoot-gallery");
          return;
        }
      } catch (error) {
        console.error("ERROR: ", error.message);
      }
      navigate("/tutorial");
    }
  };

  const getEnvStyle = () => {
    if (environment !== "production") {
      // return { backgroundColor: "#F30" };
    }
    return {};
  };

  return (
    <div className="navBarWrapper" style={getEnvStyle()}>
      <div className="navBarInner">
        {location?.pathname === "/checkout" ? (
          <button className="xButton" onClick={() => navigate("/pricing")}>
            ✕
          </button>
        ) : (
          <>
            <HamburgerMenu
              isAuthenticated={isAuthenticated}
              setAuthenticated={setAuthenticated}
            />
            <img
              className="honeycombTransformsLogo"
              src={honeycombTransformsHeaderLogo}
              alt="honeycombTransforms logo"
              onClick={galleryOrTutorial}
            />
          </>
        )}
        {environment !== "production" && <>{environment}</>}
        {(location?.pathname === "/pack-picker" ||
          location?.pathname === "/upload" ||
          location?.pathname === "/baby-profile") && (
          <div className="progressBar">
            <div
              className="progressBarInner"
              style={{ width: progressBarPercentages[location?.pathname] }}
            ></div>
            <span>{progressBarPercentages[location?.pathname]}</span>
          </div>
        )}
      </div>
    </div>
  );
}

export default NavBar;
