import axios from "axios";
import { partnerId } from "./constants";
import { getEnvironment } from "./environmentHelpers";

export const fetchJobResultJson = async (jobId) => {
  try {
    const response = await axios.get(
      `https://honeycomb-submissions.s3.us-west-2.amazonaws.com/transform/${getEnvironment()}/${partnerId}/${jobId}/transforms/result.json`
    );
    const data = response.data;
    return data;
  } catch (error) {
    console.error("Error fetching results:", error);
  }
};
