import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LocalStorageKeys } from "../../../utils/constants";
import { getTransformPartnerJobs } from "../../../utils/graphql";
import { cleanEmail } from "../../../utils/formHelpers";

const HamburgerMenu = ({ isAuthenticated, setAuthenticated }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const galleryOrTutorial = async () => {
    const isOnboarded = localStorage.getItem(LocalStorageKeys.isOnboarded);
    if (isOnboarded === true) {
      navigate("/photoshoot-gallery");
    } else {
      const email = localStorage.getItem(LocalStorageKeys.email);
      try {
        const fetchedJobs = await getTransformPartnerJobs(email);
        if (fetchedJobs.length > 0) {
          localStorage.setItem(LocalStorageKeys.isOnboarded, true);
          navigate("/photoshoot-gallery");
          return;
        }
      } catch (error) {
        console.error("ERROR: ", error.message);
      }
      navigate("/tutorial");
    }
  };

  const logout = () => {
    // remove local auth key
    const userEmail = localStorage.getItem(LocalStorageKeys.email);
    const emailClean = cleanEmail(userEmail);
    const localAuthKey = `isAuthenticated-${emailClean}`;
    localStorage.removeItem(localAuthKey);

    // Clear all local storage
    localStorage.clear();

    // Restore the email value
    if (
      emailClean !== null &&
      emailClean !== undefined &&
      emailClean !== "null"
    ) {
      localStorage.setItem(LocalStorageKeys.email, emailClean);
    }
    setAuthenticated(false);
    navigate("/");
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  if (!isAuthenticated) {
    return null;
  }

  return (
    <div style={styles.hamburgerMenu} ref={menuRef}>
      <button style={styles.hamburgerButton} onClick={toggleMenu}>
        ☰
      </button>
      {isOpen && (
        <div style={styles.menuDropdown}>
          <div
            style={styles.menuItem}
            onClick={() => {
              setIsOpen(false);
              galleryOrTutorial();
            }}
          >
            My Photoshoots
          </div>
          <a
            style={styles.menuItem}
            onClick={() => setIsOpen(false)}
            href="mailto:support@joinhoneycomb.com"
            target="_blank"
            rel="noreferrer"
          >
            Contact Us
          </a>
          <div
            style={styles.menuItem}
            onClick={() => {
              setIsOpen(false);
              logout();
            }}
          >
            Log Out
          </div>
        </div>
      )}
    </div>
  );
};

export default HamburgerMenu;

const styles = {
  hamburgerMenu: {
    position: "absolute",
    top: "0",
    right: "0",
    userSelect: "none",
    MozUserSelect: "none",
    WebkitUserSelect: "none",
    msUserSelect: "none",
  },
  hamburgerButton: {
    background: "none",
    border: "none",
    fontSize: "24px",
    color: "black",
    width: "32px",
    cursor: "pointer",
  },
  menuDropdown: {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    top: "40px",
    right: "0",
    backgroundColor: "#f9f9f9",
    border: "1px solid #ccc",
    borderRadius: "5px",
    padding: "10px",
    width: "140px",
    zIndex: 999,
  },
  menuItem: {
    textDecoration: "none",
    color: "#333",
    padding: "5px",
    userSelect: "none",
    MozUserSelect: "none",
    WebkitUserSelect: "none",
    msUserSelect: "none",
    cursor: "pointer",
  },
  menuItemHover: {
    backgroundColor: "#ddd",
  },
};
