import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { useNavigate } from "react-router-dom";
import { SquareButton } from "../../common/Buttons/Buttons";
import PackOption from "./PackOption";
import { packs } from "./Packs";
import "./PackPickerPage.css";
import { LocalStorageKeys } from "../../../utils/constants";

function PackPickerPage() {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/pack-picker",
      title: "Pack Picker",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigate = useNavigate();
  const [selected, setSelected] = useState(() => {
    const storedPackId = localStorage.getItem(LocalStorageKeys.packId);
    return storedPackId ? packs.find((pack) => pack.id === storedPackId) : "";
  });

  const onSubmit = () => {
    ReactGA.event({
      category: "cta_button",
      action: "click",
      label: "Continue",
    });
    localStorage.setItem(LocalStorageKeys.packId, selected.id);
    localStorage.setItem(LocalStorageKeys.packName, selected.title);

    navigate("/upload");
  };

  return (
    <div className="packPickerContainer">
      <h1>Choose Your Style</h1>
      <p>
        Each photoshoot creates unique photos every time. You will never get the
        same results twice.
      </p>
      <div className="packOptions">
        {packs.map((pack) => (
          <PackOption
            key={pack.id}
            pack={pack}
            selected={selected}
            setSelected={setSelected}
          />
        ))}
      </div>
      <SquareButton
        className="stickyButton"
        disabled={!selected}
        onClick={onSubmit}
      >
        Continue
      </SquareButton>
      <div className="gradient"></div>
    </div>
  );
}

export default PackPickerPage;
