import { useState } from "react";
import ReactGA from "react-ga4";
import { isMobile, isIOS } from "react-device-detect";

const PackOption = ({ pack, selected, setSelected }) => {
  const [loaded, setLoaded] = useState(false);

  const isSelected = selected.id === pack.id;

  return (
    <label
      className={`radio ${
        isSelected ? "selected" : selected === "" ? "" : "unselected"
      } ${isMobile && isIOS ? "ios" : ""}`}
    >
      <div className="radio-inner">
        <input
          type="radio"
          onClick={() => {
            ReactGA.event({
              category: "radio_input",
              action: "click",
              label: pack.title,
            });
            setSelected(pack);
          }}
          onChange={() => {}}
          checked={isSelected}
          name="pack"
        />
        <div className="radioText">
          <span className="title">{pack.title}</span>
        </div>
      </div>
      <div className={`image-wrapper ${loaded ? "loaded" : ""}`}>
        <div className="placeholder"></div>
        <img
          className="real-image"
          src={pack.image}
          alt={pack.title}
          onLoad={() => {
            setLoaded(true);
          }}
        />
      </div>
    </label>
  );
};

export default PackOption;
