import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Olark from "react-olark";
import { PostHogProvider } from "posthog-js/react";
import { POSTHOG_HOST, POSTHOG_KEY } from "./utils/constants";
import { getEnvironment } from "./utils/environmentHelpers";

const posthogOptions = {
  api_host: POSTHOG_HOST,
  capture_pageview: false,
};

const AppWithOlark = () => (
  <>
    <App />
    <Olark siteId="1671-236-10-4042" />
  </>
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    {getEnvironment() === "staging" ? (
      <AppWithOlark />
    ) : (
      <PostHogProvider apiKey={POSTHOG_KEY} options={posthogOptions}>
        <AppWithOlark />
      </PostHogProvider>
    )}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
