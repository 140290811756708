import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
import { SquareButton } from "../../common/Buttons/Buttons";

function TimeoutPage() {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/timeout",
      title: "Timeout",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigate = useNavigate();

  return (
    <div style={styles.container}>
      <h1>Time limit reached</h1>
      <p>
        This photoshoot is no longer available, but you can generate a new one
        with the same photos. Click here to rejoin the queue.
      </p>
      <SquareButton style={styles.button} onClick={() => navigate("/pricing")}>
        Back
      </SquareButton>
    </div>
  );
}

export default TimeoutPage;

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "1.5rem",
    maxWidth: "600px",
    margin: "0 auto",
  },
  button: {
    margin: "2rem auto",
  },
};
