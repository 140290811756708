import React, { useEffect, useState, useRef } from "react";
import ReactGA from "react-ga4";
import { useNavigate } from "react-router-dom";
import { SquareButton } from "../../common/Buttons/Buttons";
import { executeTransformPartnershipJob } from "../../../utils/graphql";
import { LocalStorageKeys } from "../../../utils/constants";

const executeJob = async (jobId, navigate) => {
  try {
    await executeTransformPartnershipJob(jobId);
    // Remove job id to avoid repeat creation
    localStorage.removeItem(LocalStorageKeys.jobId);
    localStorage.removeItem(LocalStorageKeys.trainingImages);
    localStorage.removeItem(LocalStorageKeys.packId);
    localStorage.removeItem(LocalStorageKeys.packName);
  } catch (error) {
    console.error("Error creating partnership job:", error);
    // Handle error appropriately (e.g., show error message to user)
  }
};

function PaymentConfirmationPage() {
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const navigate = useNavigate();
  const isCreating = useRef(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setButtonDisabled(false);
    }, 5000);
    ReactGA.send({
      hitType: "pageview",
      page: "/payment-confirmation",
      title: "Payment Confirmation",
    });

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isCreating.current === true) {
      return;
    } else {
      isCreating.current = true;
    }

    const jobId = localStorage.getItem(LocalStorageKeys.jobId);

    executeJob(jobId, navigate);
  }, [navigate]);

  return (
    <div style={styles.container}>
      <h1 style={styles.header}>🎉 Your photos are being created</h1>
      <p style={styles.paragraph}>
        Thank you! Your photos will be ready in about <strong>2 hours</strong>.
        We will email you when they are ready.
      </p>
      <p style={styles.paragraph}>
        Questions or feedback? Contact us at{" "}
        <a
          style={styles.link}
          href="mailto:support@joinhoneycomb.com"
          target="_blank"
          rel="noreferrer"
        >
          support@joinhoneycomb.com
        </a>
        .
      </p>
      <SquareButton
        style={styles.cta}
        onClick={() => navigate("/photoshoot-gallery")}
        disabled={buttonDisabled}
      >
        {buttonDisabled ? <>Loading...</> : "Go To My Photoshoots →"}
      </SquareButton>
    </div>
  );
}

export default PaymentConfirmationPage;

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "1.5rem",
    maxWidth: "390px",
    margin: "0 auto",
    textAlign: "left",
  },
  spinner: {
    border: "4px solid rgba(0, 0, 0, 0.1)",
    borderLeftColor: "#333",
    borderRadius: "50%",
    width: "18px",
    height: "18px",
    animation: "spin 1s linear infinite",
    marginRight: "0.75rem",
    display: "inline-block",
  },
  link: {
    color: "#333",
  },
  header: {
    width: "100%",
    textAlign: "center",
  },
  paragraph: {
    width: "100%",
  },
  cta: {
    display: "flex",
    margin: "2rem auto",
    alignItems: "center",
    justifyContent: "center",
  },
};
