import React from "react";
import { useNavigate } from "react-router-dom";

function PhotoshootGalleryItem({ transformJob }) {
  const navigate = useNavigate();

  const previewImage =
    transformJob.previewImage ||
    "https://honeycomb-static-assets.s3.us-west-2.amazonaws.com/39ff28b3-7346-464d-9913-7b88245c8abd.png";

  const navToPhootoshoot = () => {
    if (transformJob.jobStatus === "PROCESSING") {
      return;
    }

    navigate(`/photoshoot/${transformJob.uuid}`);
  };

  const tileStyle = {
    backgroundImage: `url(${previewImage})`,
    cursor: transformJob.jobStatus === "COMPLETED" ? "pointer" : "default",
  };

  return (
    <div className="tile" style={tileStyle} onClick={navToPhootoshoot}>
      {transformJob.jobStatus === "PROCESSING" && (
        <div className="text">
          Creating {transformJob.babyName}'s {transformJob.packName}{" "}
          Photoshoot... <br />
          <br />
          We will email when ready!
          <div className="spinner"></div>
        </div>
      )}
      {transformJob.jobStatus === "COMPLETED" && (
        <div className="text">
          {transformJob.babyName}'s {transformJob.packName} Photoshoot
        </div>
      )}
      <div className="blur"></div>
    </div>
  );
}

export default PhotoshootGalleryItem;
