import React, { useEffect, useState, useRef } from "react";
import ReactGA from "react-ga4";
import { SquareButton } from "../../common/Buttons/Buttons";
import shield from "../../../assets/images/shield.webp";
import quotes from "../../../assets/images/quotes.webp";
import testimonialBig from "../../../assets/images/testimonialBig.webp";
import testimonialSmall from "../../../assets/images/testimonialSmall.webp";
import "./PricingPage.css";
import PricingItem from "./PricingItem";
import { getPaymentLinks } from "../../../utils/constants";

export const pricingButtonType = {
  SELECT: "Select",
  CONTACT_US: "Contact us",
};

const pricingItems = [
  {
    title: "Starter",
    strikePrice: "$20",
    price: "$10",
    stripeLink: getPaymentLinks().dollar10,
    priceId: "price_1PWn2lB90ZPAyf89Ip9cTDOS",
    button: pricingButtonType.SELECT,
    returnedOutputs: 50,
    features: ["50 Unique photos", "Standard style options"],
    featuresMobile: ["50 photos", "Standard styles"],
    containerStyle: "pricingCard",
    turnAroundTime: "2 hour",
  },
  {
    title: "Classic",
    strikePrice: "$30",
    price: "$15",
    stripeLink: getPaymentLinks().dollar15,
    priceId: "price_1PWn3TB90ZPAyf89CGUlnndX",
    button: pricingButtonType.SELECT,
    returnedOutputs: 100,
    features: ["100 Unique photos", "Standard style options"],
    featuresMobile: ["100 photos", "Standard styles"],
    containerStyle: "pricingCard popular",
    turnAroundTime: "2 hour",
  },
];

function PricingPage() {
  const intervalRef = useRef(null);
  const [timer, setTimer] = useState("8:00");
  const [showModal, setShowModal] = useState(false);
  const [codeCopied, setCodeCopied] = useState(false);

  const getTimeRemaining = (deadline) => {
    const total = Date.parse(deadline) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    return {
      total,
      minutes,
      seconds,
    };
  };

  const startTimer = (deadline) => {
    intervalRef.current = setInterval(() => {
      const { total, minutes, seconds } = getTimeRemaining(deadline);
      if (total >= 0) {
        setTimer(`${minutes}:${seconds < 10 ? "0" + seconds : seconds}`);
        if (minutes === 7 && seconds === 50) {
          setShowModal(true);
        }
      } else {
        clearInterval(intervalRef.current);
        window.location = "/timeout";
      }
    }, 1000);
  };

  const clearTimer = () => {
    clearInterval(intervalRef.current);
  };

  const getDeadTime = () => {
    const deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + 8 * 60);
    return deadline;
  };

  useEffect(() => {
    const deadline = getDeadTime();
    ReactGA.send({
      hitType: "pageview",
      page: "/pricing",
      title: "Pricing",
    });
    startTimer(deadline);

    return () => {
      clearTimer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="pricingPage">
      <div className="leftSection">
        <h1>Claim your photos now</h1>
        <p className="description">
          Your unique set is one of a kind and will never be replicated again.
          Unlock them now to treasure forever.
        </p>
        <div className="infoSection">
          <p className="guarantee">
            <img src={shield} alt="" />
            <span>100% Money Back Guarantee</span>
          </p>
          <p className="countdown">Time left: {timer}</p>
        </div>
        <div className="pricingCards">
          {pricingItems.map((item, index) => (
            <PricingItem key={index} item={item} />
          ))}
        </div>
        <div className="newsSources">
          <p>As seen in</p>
          <img className="testimonialBig" src={testimonialBig} alt="" />
          <img className="testimonialSmall" src={testimonialSmall} alt="" />
        </div>
      </div>
      <div className="rightSection">
        <h2 className="slideshowTitle">
          <span>Millions of Photos</span>
          <br />
          Created by Happy Customers
        </h2>
        <div id="slide-container">
          <div id="slide-first-element">
            <div className="slide-image"></div>
            <p className="slide-text">
              <img src={quotes} alt="" />
              Mine came out so good, looks just like her. Now I don't have to
              schedule photos!<span>- Sophia's mom, Ana C.</span>
            </p>
          </div>
          <div id="slide-element-2">
            <div className="slide-image"></div>
            <p className="slide-text">
              <img src={quotes} alt="" />
              😍😍😍😍😍😍😍😍😍😍😍
              <br />
              These are sooooo adorable!!!! I LOVEEEEE them!!!!!!!
              <span>- Nolan's mom, Leah B.</span>
            </p>
          </div>
          <div id="slide-element-3">
            <div className="slide-image"></div>
            <p className="slide-text">
              <img src={quotes} alt="" />
              Used it to make unicorn invites for my little girl's birthday. Too
              fun<span>- Charlotte's mom, Christina T.</span>
            </p>
          </div>
        </div>
      </div>
      {showModal && (
        <div className="modalOverlay">
          <div className="couponBox">
            <button className="xButton" onClick={() => setShowModal(false)}>
              ✕
            </button>
            <h2>Get 10% OFF</h2>
            <p>
              Get your AI photoshoot today. As a promotional offer, take 10% off
              your purchase.
            </p>
            <div className="flex">
              <SquareButton
                className={codeCopied ? "couponButton copied" : "couponButton"}
                onClick={() => {
                  ReactGA.event({
                    category: "cta_button",
                    action: "click",
                    label: "Copy Code",
                  });
                  navigator.clipboard.writeText("NEW10");
                  setCodeCopied(true);
                }}
              >
                {codeCopied ? "Code Copied!" : "Coupon: NEW10"}
              </SquareButton>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default PricingPage;
