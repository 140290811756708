import React from "react";
import quotes from "../../../assets/images/quotes.webp";
import testimonial1 from "../../../assets/images/testimonial_1.webp";
import testimonial2 from "../../../assets/images/testimonial_2.webp";
import testimonial3 from "../../../assets/images/testimonial_3.webp";

import "./CheckoutPage.css";
import CheckoutForm from "./CheckoutForm";

import { useLocation } from "react-router-dom";

function CheckoutPage() {
  const location = useLocation();
  const priceId = location.state?.priceId;

  return (
    <div className="paymentPage">
      <div className="leftSection">
        <div className="mobileOnly">
          <h2 className="slideshowTitle">
            <span>Millions of Photos</span>
            <br />
            Created by Happy Customers
          </h2>
          <div className="testimonialImages">
            <img src={testimonial1} alt="" />
            <img src={testimonial2} alt="" />
            <img src={testimonial3} alt="" />
          </div>
        </div>
        <CheckoutForm priceId={priceId} />
      </div>
      <div className="rightSection">
        <h2 className="slideshowTitle">
          <span>Millions of Photos</span>
          <br />
          Created by Happy Customers
        </h2>
        <div id="slide-container">
          <div id="slide-first-element">
            <div className="slide-image"></div>
            <p className="slide-text">
              <img src={quotes} alt="" />
              Mine came out so good, looks just like her. Now I don't have to
              schedule photos!<span>- Sophia's mom, Ana C.</span>
            </p>
          </div>
          <div id="slide-element-2">
            <div className="slide-image"></div>
            <p className="slide-text">
              <img src={quotes} alt="" />
              😍😍😍😍😍😍😍😍😍😍😍
              <br />
              These are sooooo adorable!!!! I LOVEEEEE them!!!!!!!
              <span>- Nolan's mom, Leah B.</span>
            </p>
          </div>
          <div id="slide-element-3">
            <div className="slide-image"></div>
            <p className="slide-text">
              <img src={quotes} alt="" />
              Used it to make unicorn invites for my little girl's birthday. Too
              fun<span>- Charlotte's mom, Christina T.</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CheckoutPage;
