import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import "./drop-uploader.css";
import UploadPreview from "./UploadPreview";
import { deleteFileFromS3, uploadToS3 } from "../../../../utils/s3Utils";
import {
  LocalStorageKeys,
  uploadErrorMessages,
} from "../../../../utils/constants";

const DropUploader = ({ onUploadCountChange, jobId }) => {
  const [files, setFiles] = useState(() => {
    const storedFiles = localStorage.getItem(LocalStorageKeys.trainingImages);
    return storedFiles ? JSON.parse(storedFiles) : [];
  });

  const [uploadedCountText, setUploadedCountText] = useState("");

  const uploadingOrCompletedCount = files.filter(
    (file) =>
      file.uploadStatus === "uploading" || file.uploadStatus === "completed"
  ).length;
  const [errorMessage, setErrorMessage] = useState();

  useEffect(() => {
    const completedCount = files.filter(
      (file) => file.uploadStatus === "completed"
    ).length;

    setUploadedCountText(
      completedCount === 0
        ? ""
        : `${completedCount} Photo${completedCount === 1 ? "" : "s"} Uploaded`
    );

    onUploadCountChange(completedCount);
  }, [files, onUploadCountChange]);

  useEffect(() => {
    const completedFiles = files.filter(
      (file) => file.uploadStatus === "completed"
    );
    localStorage.setItem(
      LocalStorageKeys.trainingImages,
      JSON.stringify(completedFiles)
    );
  }, [files]);

  const updateFileStatus = (fileToUpdate, status) => {
    setFiles((prevFiles) =>
      prevFiles.map((prevFile) =>
        prevFile.path === fileToUpdate.path
          ? { ...prevFile, uploadStatus: status }
          : prevFile
      )
    );
  };

  const updateFilePreviewAndName = (fileToUpdate, preview, filetype) => {
    setFiles((prevFiles) =>
      prevFiles.map((prevFile) =>
        prevFile.path === fileToUpdate.path
          ? { ...prevFile, preview: preview, type: filetype }
          : prevFile
      )
    );
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/heic": [".heic"],
      "image/heif": [".heif"],
      "image/jpeg": [".jpg", ".jpeg"],
      "image/png": [".png"],
    },
    onDrop: (acceptedFiles, fileRejections) => {
      setErrorMessage(undefined);

      if (fileRejections.length > 0) {
        setErrorMessage(uploadErrorMessages.fileFormat);
      }

      // Calculate how many more files can be accepted
      const maxFilesAllowed = 10 - uploadingOrCompletedCount;

      if (maxFilesAllowed <= 0) {
        setErrorMessage(uploadErrorMessages.maxUploads);
      }

      // Truncate acceptedFiles to ensure we stay within the limit
      const filesToUpload = acceptedFiles.slice(0, maxFilesAllowed);

      const existingFailedFiles = files.filter(
        (file) => file.uploadStatus === "failed"
      );

      const newFiles = filesToUpload.filter((file) => {
        const fileNameWithoutExt = file.path.split(".")[0]; // Get filename without extension
        const cleanFileName = fileNameWithoutExt.replace(/ /g, "+"); // Replace spaces with +

        const existingFile = files.find(
          (existingFile) =>
            existingFile.path.split(".")[0].replace(/ /g, "+") ===
              cleanFileName &&
            (existingFile.uploadStatus === "uploading" ||
              existingFile.uploadStatus === "completed")
        );

        if (existingFile !== undefined) {
          // Set error message and color
          setErrorMessage(uploadErrorMessages.duplicateFile);
        }

        return !files.some(
          (existingFile) =>
            existingFile.path.split(".")[0].replace(/ /g, "+") ===
              cleanFileName &&
            (existingFile.uploadStatus === "uploading" ||
              existingFile.uploadStatus === "completed")
        );
      });

      // Prepare new files with initial states
      const updatedFiles = newFiles.map((file) => ({
        ...file,
        preview: URL.createObjectURL(file),
        uploadStatus: "uploading", // "uploading, "failed", "completed"
        uploadAttempts: 0,
        size: file.size,
        type: file.type,
      }));

      // Update state with new files
      setFiles((prevFiles) => {
        // filter out and remove duplicate existing failed files
        prevFiles.filter(
          (prevFile) =>
            !existingFailedFiles.some(
              (failedFile) => failedFile.path === prevFile.path
            )
        );

        return [...prevFiles, ...updatedFiles];
      });

      // Update state with new files, removing existing failed files
      setFiles((prevFiles) =>
        existingFailedFiles // Check if needed
          ? prevFiles.filter(
              (prevFile) =>
                !existingFailedFiles.some(
                  (failedFile) => failedFile === prevFile
                )
            )
          : prevFiles // Keep existing files if no removal
              .concat(updatedFiles)
      );

      // Now upload to S3 for the new files
      newFiles.forEach((file) => {
        uploadToS3(
          file,
          jobId,
          updateFileStatus,
          updateFilePreviewAndName,
          setErrorMessage
        );
      });
    },
  });

  const onRemove = async (file) => {
    setErrorMessage(undefined);

    try {
      // delete from s3
      await deleteFileFromS3(file, jobId);

      // Remove locally from state
      const updatedFiles = files.filter((f) => f !== file);
      setFiles(updatedFiles);
    } catch (error) {
      console.error("Error deleting file", error);
    }
  };

  return (
    <div className="dropUploadContainer">
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <button className="uploadButton">+ Upload Photos</button>
        <p className="dropzoneHelperText">Click to upload or drag and drop</p>
        {errorMessage && (
          <p
            style={{ color: `${errorMessage.color}` }}
            className="errorMessage"
          >
            {errorMessage.text}
          </p>
        )}
      </div>
      <p className="uploadCountText">{uploadedCountText}</p>
      <aside className="thumbsGrid">
        {files.map((file, index) => (
          <UploadPreview
            key={file.path + index}
            file={file}
            onRemove={onRemove}
          />
        ))}
      </aside>
    </div>
  );
};

export default DropUploader;
