import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";

import React, { useCallback, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { LocalStorageKeys } from "../../../utils/constants";
import { getEnvironment } from "../../../utils/environmentHelpers";

const stripePromise = loadStripe(
  "pk_live_51PK5h6B90ZPAyf89bdTLWTAVvbi28pG8rcwLaYeNVgLCsQc4D8ODwk5reoQFfr2u10kbbmGLntPHHXj7HKNkSRuU00gdypNRiW"
);

const baseLink =
  getEnvironment() === "staging"
    ? "https://staging.joinhoneycomb.com/payments"
    : "https://app.joinhoneycomb.com/payments";

const CheckoutForm = ({ priceId }) => {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState(null);

  const fetchClientSecret = useCallback(async () => {
    if (fetching) return;
    setFetching(true);
    // Create a Checkout Session
    const checkoutSession = await fetch(
      `${baseLink}/create-checkout-session/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          price_id: priceId,
          email: localStorage.getItem(LocalStorageKeys.email),
        }),
      }
    );

    const responseJson = await checkoutSession.json();
    const clientSecret = responseJson.clientSecret;

    setOptions({ clientSecret });
    // return clientSecret;
  }, [priceId, fetching]);

  if (!options) {
    fetchClientSecret();
    return <> </>;
  }

  return (
    <div id="checkout">
      <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  );
};

export default CheckoutForm;
