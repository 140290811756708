import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { SquareButton } from "../../common/Buttons/Buttons";
import babyProfileImage from "../../../assets/images/baby_profile.png";
import "./BabyProfilePage.css";
import { LocalStorageKeys } from "../../../utils/constants";
import { useNavigate } from "react-router-dom";
import { updateTransformPartnershipJob } from "../../../utils/graphql";

function BabyProfilePage() {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/baby-profile",
      title: "Baby Profile",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [firstName, setFirstName] = useState(
    localStorage.getItem(LocalStorageKeys.babyName) || ""
  );
  const [gender, setGender] = useState(
    localStorage.getItem(LocalStorageKeys.gender) || ""
  );
  // const [birthday, setBirthday] = useState("");
  const [submitting, setSubmitting] = useState(false);
  // const [dateInputClicked, setDateInputClicked] = useState(false);

  const navigate = useNavigate();

  const previewImage = localStorage.getItem(LocalStorageKeys.previewImageUrl);

  // const today = new Date();
  // var dateString = new Date(today.getTime() - today.getTimezoneOffset() * 60000)
  //   .toISOString()
  //   .split("T")[0];

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (submitting) return;
    ReactGA.event({
      category: "cta_button",
      action: "click",
      label: "Next (Baby Profile Page)",
    });
    setSubmitting(true);
    localStorage.setItem(LocalStorageKeys.babyName, firstName);
    // localStorage.setItem(LocalStorageKeys.birthdate, birthday);
    localStorage.setItem(LocalStorageKeys.gender, gender);

    try {
      await updateTransformPartnershipJob("BABY_DETAILS");

      navigate("/pricing");
    } catch {
      navigate("/login");
      setSubmitting(false);
    }
  };

  return (
    <div className="babyProfileContainer">
      <h1>Last step!</h1>
      <div className="innerContainer">
        <p>
          Help us generate perfect photos that are personalized to your little
          one.
        </p>
        <div
          className="imageWrapper"
          style={{
            backgroundImage: `url(${previewImage || babyProfileImage})`,
          }}
        ></div>
        <input
          type="text"
          id="name"
          name="name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          placeholder="Child's First Name"
          className="emailInput"
          required
        />
        <div className="selectWrapper">
          <select
            className={gender !== "" ? "genderSelect active" : "genderSelect"}
            id="gender"
            name="gender"
            value={gender}
            onChange={(e) => setGender(e.target.value)}
            tabIndex={0}
          >
            <option value="" disabled>
              Gender
            </option>
            <option value="girl">Girl</option>
            <option value="boy">Boy</option>
          </select>
        </div>
        {/* <input
          type="date"
          id="birthday"
          name="birthday"
          placeholder="Birthdate"
          className={`dateInput ${dateInputClicked ? "clicked" : ""} ${
            birthday ? "dirty" : ""
          } ${isMobile && isIOS ? "ios" : ""}`}
          required
          max={dateString}
          onFocus={() => setDateInputClicked(true)}
          onChange={(e) => setBirthday(e.target.value)}
        /> */}
        <SquareButton
          disabled={!firstName.trim() || !gender /* || !birthday*/}
          onClick={handleSubmit}
        >
          Continue
        </SquareButton>
      </div>
    </div>
  );
}

export default BabyProfilePage;
