import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { SquareButton } from "../../common/Buttons/Buttons";
import "./PhotoshootGalleryPage.css";
import { LocalStorageKeys } from "../../../utils/constants";
import { getTransformPartnerJobs } from "../../../utils/graphql";
import PhotoshootGalleryItem from "./PhotoshootGalleryItem";
import { useNavigate } from "react-router-dom";

function PhotoshootGalleryPage() {
  const navigate = useNavigate();

  const [transformJobs, setTransformJobs] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (loading) {
      return;
    }
    ReactGA.send({
      hitType: "pageview",
      page: "/photoshoot-gallery",
      title: "Photoshoot Gallery",
    });

    setLoading(true);
    const fetchJobs = async () => {
      const email = localStorage.getItem(LocalStorageKeys.email);
      try {
        const fetchedJobs = await getTransformPartnerJobs(email);
        const chronologicalJobs = fetchedJobs.sort((a, b) => a.createdAt - b.createdAt);
        const completedJobs = [];
        const runningJobs = [];
        for (const job of chronologicalJobs) {
          if (job.jobStatus === 'COMPLETED') {
            completedJobs.push(job)
          } else {
            runningJobs.push(job)
          }
        }
        const orderedJobs = [...runningJobs, ...completedJobs];
        setTransformJobs(orderedJobs);
      } catch (error) {
        console.error("ERROR: ", error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchJobs();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="galleryContainer">
      <h1>My Photoshoots</h1>
      <SquareButton
        onClick={() => {
          ReactGA.event({
            category: "cta_button",
            action: "click",
            label: "Create Photoshoot",
          });

          navigate("/pack-picker");
        }}
        className="new-button"
      >
        Create Photoshoot
      </SquareButton>
      <div className="innerGrid">
        <div className="tile-grid">
          {transformJobs.map((job) => {
            return <PhotoshootGalleryItem key={job.uuid} transformJob={job} />;
          })}
        </div>
      </div>
    </div>
  );
}

export default PhotoshootGalleryPage;
