export const getEnvironment = () => {
  if (
    process.env.REACT_APP_ENVIRONMENT === "staging" ||
    process.env.REACT_APP_ENVIRONMENT === "production"
  ) {
    return process.env.REACT_APP_ENVIRONMENT;
  }
  return "development";
};

export const getGraphqlUrl = () => {
  if (getEnvironment() === "staging") {
    return "https://staging.joinhoneycomb.com/graphql/";
  } else if (getEnvironment() === "production") {
    return "https://app.joinhoneycomb.com/graphql/";
  } else {
    // TODO: use local backend, if no env set, assume "development"
    return "https://staging.joinhoneycomb.com/graphql/";
  }
};
