import JSZip from "jszip";
import { saveAs } from "file-saver";
import axios from "axios";

export const zipAndSaveFile = async (selectedItems) => {
  const zip = new JSZip();

  // Fetch each file and add it to the zip
  const promises = selectedItems.map(async (selectedItem, index) => {
    const response = await axios.get(selectedItem.url, {
      responseType: "blob",
    });
    const fileName = selectedItem.url.split("/").pop(); // Extract the file name from the URL
    const fileExt = fileName.split(".").pop();
    const newFileName = `honeycomb_photo_${index + 1}.${fileExt}`;
    zip.file(newFileName, response.data);
  });

  // Wait for all files to be added to the zip
  await Promise.all(promises);

  // Generate the zip file and trigger the download
  const content = await zip.generateAsync({ type: "blob" });
  saveAs(content, "honeycomb_photos.zip");
};

export const saveFiles = async (selectedItems) => {
  // Create an array of promises that resolve after each file is downloaded and saved
  const downloadPromises = selectedItems.map((selectedItem, index) => {
    return new Promise((resolve) => {
      setTimeout(async () => {
        try {
          const response = await axios.get(selectedItem.url, {
            responseType: "blob",
          });
          const fileName = selectedItem.url.split("/").pop(); // Extract the file name from the URL
          const fileExt = fileName.split(".").pop();
          const newFileName = `honeycomb_photo_${index + 1}.${fileExt}`;
          saveAs(response.data, newFileName);
          resolve(); // Resolve the promise after the file is saved
        } catch (error) {
          console.error(`Error downloading file ${selectedItem.url}:`, error);
          resolve(); // Resolve the promise even if there's an error to avoid blocking the rest
        }
      }, 100 * (index + 1)); // Increment the delay for each file
    });
  });

  // Wait for all download promises to complete
  await Promise.all(downloadPromises);
};

export const shareFilesIos = async (selectedItems) => {
  const fileObjects = [];

  // Download each image file and create File objects
  const downloadPromises = selectedItems.map(async (selectedItem, index) => {
    try {
      const response = await axios.get(selectedItem.url, {
        responseType: "blob",
      });
      const fileName = selectedItem.url.split("/").pop(); // Extract the file name from the URL
      const fileExt = fileName.split(".").pop();
      const newFileName = `honeycomb_photo_${index + 1}.${fileExt}`; // Rename the file
      const file = new File([response.data], newFileName, {
        type: response.data.type,
      });
      fileObjects.push(file);
    } catch (error) {
      console.error(`Error downloading file ${selectedItem.url}:`, error);
    }
  });

  // Wait for all files to be downloaded and File objects created
  await Promise.all(downloadPromises);

  if (navigator.canShare && navigator.canShare({ files: fileObjects })) {
    navigator
      .share({
        files: fileObjects,
        title: "Honeycomb Photos",
        text: "Download your photos from Xomgpop!",
      })
      .catch((error) => {
        console.error("Error sharing file:", error);
      });
  } else {
    // Fallback to file-saver if the Web Share API is not supported
    saveFiles(selectedItems);
  }
};
